import React from 'react'
import '../styles/Adventure.scss'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Link, NavLink } from 'react-router-dom'
import studyPeople from '../assets/studyPeople.jpg'
import workPeople from '../assets/workPeople.jpg'
import visas from '../assets/visas.jpg'



const Adventure = () => {
    return (

        <Container fluid className=' bg-success pb-1 pb-sm-2 pb-md-3' >
            <Container className='left-side-column'>
                <Row className="pt-4 pb-4 pb-md-5" >
                    <h2 className='fw-bold text-brown'>Comienza a vivir la Experiencia</h2>
                    <span className='text-white'>Una vez que hayamos planificado juntos tu viaje,
                        ponemos manos
                    </span>
                    <span className='text-white' >a la obra: nos ocupamos de acompañarte en todo el proceso.</span>

                </Row>
            </Container>
            <Container>
                <Row className='justify-content-between py-0 py-sm-1 py-md-3  gap-1 gap-sm-3 gap-md-5'>
                    <Col sm className="col-lg-3 d-flex align-items-stretch">
                        <Card className="text-center mx-auto border-0" style={{ width: '16rem' }}>
                            <Card.Img variant="top" src={studyPeople} />
                            <Card.Body >
                                <Card.Title><h2>Estudia</h2></Card.Title>
                                <Card.Text style={{ height: '12rem', color: '#676767' }}>
                                    Conoce como te podemos ayudar a elegir el curso que mas se adapte a tus necesidades.
                                </Card.Text>
                                <NavLink as={Link} to='/estudia'>
                                    <Button className='buttons adventure-button text-white' >Más Informacion</Button>
                                </NavLink>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm className="col-lg-3 d-flex align-items-stretch">
                        <Card className="text-center mx-auto border-0" style={{ width: '16rem' }}>
                            <Card.Img variant="top" src={workPeople} />
                            <Card.Body>
                                <Card.Title><h2>Trabaja</h2></Card.Title>
                                <Card.Text style={{ height: '12rem', color: '#676767' }}>
                                    Con tu visado ademas de estudiar podes trabajar. Te brindamos informacion acerca de tramites y como buscar trabajo.
                                </Card.Text>
                                <NavLink as={Link} to='/contactanos'>
                                    <Button className='buttons adventure-button text-white'>Más Informacion</Button>
                                </NavLink>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col sm className="col-lg-3 d-flex align-items-stretch">
                        <Card className="text-center mx-auto border-0" style={{ width: '16rem' }}>
                            <Card.Img variant="top" src={visas} />
                            <Card.Body>
                                <Card.Title><h2>Visas</h2></Card.Title>
                                <Card.Text style={{ height: '12rem', color: '#676767' }}>
                                    Si estas pensando en viajar a Australia para estudiar y trabajar, necesitas de un visado. Nosotros te podemos ayudar a gestionar tu visa de estudiante.
                                </Card.Text>
                                <NavLink as={Link} to='/contactanos'>
                                    <Button className='buttons adventure-button text-white'>Más Informacion</Button>
                                </NavLink>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </Container>

    )
}

export default Adventure