import React from 'react'
import '../styles/Study.scss'
import BlueLogo from '../assets/Blue-Logo.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'






const Study = () => {
    return (

        <Container fluid>


            <Container>

                <Row className='  pb-3 pt-1 pt-sm-3 pt-md-4'>
                    <Col>
                        <h1 className=' fw-bold big-text text-brown'>Estudiar en</h1>
                        <span><h1 className='sub-title fw-bold big-text'>Australia</h1></span>
                        <p className='lh-base'>
                            <span>Australia es uno de los países con mayor calidad de vida del mundo, en donde podrás estudiar y trabajar al mismo tiempo.
                                Lo más habitual entre nuestros estudiantes es empezar su aventura estudiando un curso de inglés. Luego, a medida que mejoran su nivel de inglés, pueden empezar cursos VET o incluso Cursos Universitarios. </span>
                            <span>
                                En caso de que no sepas inglés, ¡no te estreses! Los cursos de Inglés están para TODOS los niveles.
                                Y cuando alcances el nivel de inglés requerido, podrás Estudiar alguno de nuestros cursos Vocacionales (VET).
                            </span>
                        </p>
                        <Row className=' pb-3 pt-1 pt-sm-3 pt-md-4'>
                            <NavLink to='/contactanos'>
                                <Button className='team-button buttons'>Contactanos</Button>
                            </NavLink>
                        </Row>
                    </Col>
                    <Col sm ><img src={BlueLogo} className="d-none d-md-block img-fluid logo-disable" /></Col>

                </Row>
            </Container>
        </Container>


    )
}

export default Study