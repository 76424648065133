import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import '../styles/Header.scss'
import BrownLogo from '../assets/LogoBrownWelcomeMate.png'
import { Link as LinkScroll } from 'react-scroll'

const Header = () => {
    return (
        <Container fluid className='bg-success' >
            <Container >
                <Row>
                    <Col sm className=' d-flex justify-content-start align-items-center   pt-1 pt-sm-2 pt-md-5 pb-1 pb-sm-3 pb-md-5'>
                        <Container className="ps-0">
                            <h1 className="text-white fw-bold big-text">Conoce<br /> Welcome Mate</h1>
                            <span><h1 className="text-brown fw-bold big-text">Australia</h1></span>
                            <p className="text-white lh-base">Te ayudamos desde nuestra propia<br /> experiencia, asesorandote y resolviendo<br /> todas las dudas que tengas.</p>
                            <Container className="d-flex justify-content-start ps-0 mt-1 mt-sm-3 mt-md-5">
                                <LinkScroll to='aboutUs' spy={true} smooth={true} offset={40} duration={500}>
                                    <Button className='me-1 me-sm-3 me-md-5 buttons team-button' >Nosotros</Button></LinkScroll>
                                {/* <LinkScroll to='contactForm' spy={true} smooth={true} offset={25} duration={500}><Button className="buttons start-button" >Comienza Ahora</Button></LinkScroll> */}
                            </Container>
                        </Container>
                    </Col>
                    <Col sm ><img src={BrownLogo} className=" d-none d-md-block img-fluid logo-disable" /></Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Header