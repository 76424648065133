import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import '../styles/StartExperience.scss'
import oceanLogo from '../assets/4_PIEZAS-20.jpg'
import { Link as LinkScroll } from 'react-scroll'

const StartExperience = () => {
    return (
        <Container fluid style={{ padding: 0 }}>
            <div style={{ display: 'flex' }}>
                <Container className='left-column'>
                    <div className='left-column-content'>
                        <Row>
                            <h2 className='text-white text-content'>
                                Comienza hoy la <br /> experiencia que sueñas
                            </h2>
                        </Row>
                        <Row className='text-content'>
                            <p>
                                Te podemos ayudar a elegir el curso que más se adapta a tus necesidades,
                                rellena el formulario y nos comunicaremos contigo.

                            </p>

                        </Row>
                        <Row>
                            <Col className='start-button-container'>
                                <LinkScroll to='contactForm' spy={true} smooth={true} offset={25} duration={500}>
                                    <Button className='buttons start-button'> Contactanos</Button>
                                </LinkScroll>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className='image-container'>
                    <img src={oceanLogo} className="image" />
                </div>
            </div >
        </Container >
    )
}

export default StartExperience