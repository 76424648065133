import React from 'react'
import { useState } from 'react';
import { Col, Container, Row, Card, NavLink } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';
import '../styles/Footer.scss'


const Footer = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (


        <Container fluid className=' bg-success  text-white'>
            <div className='footer-content'>
                <Row className='mb-3'>
                    <h1 className='text-center'> Conoce Welcome Mate Australia</h1>
                </Row>
                <Row className='mb-3'>
                    <p className='text-center description'>
                        Estudia, trabaja y vive la experiencia que tanto esperabas en uno de los paises con mejor
                        calidad de vida. Haz amigos de todo el mundo, explora y vive experiencias únicas.
                    </p>
                </Row>
                <Row>
                    <Col>
                        <i></i>
                    </Col>
                    <Col className='text-center'>
                        <Button className='buttons work-button' onClick={handleShow}> Trabaja con nosotros </Button>
                    </Col>
                    <Col>
                        <i></i>
                    </Col>
                </Row>
            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Trabaja con Nosotros</Modal.Title>
                </Modal.Header>
                <Modal.Body>Escribenos! info@welcomemateaustralia.com</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>

                </Modal.Footer>
            </Modal>

        </Container>



    )
}

export default Footer