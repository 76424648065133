import React from 'react'
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import '../styles/FormComponent.scss'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';


const FormComponent = () => {

    //Form validation
    const [validated, setValidated] = useState(false);
    const [filled, setFilled] = useState(false);
    const [show, setShow] = useState(false);
    const form = useRef();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            handleShow();
        }
        else if (form.checkValidity() === true) {
            setFilled(true);
            event.preventDefault();
            handleShow();
            sendEmail(event);
        }
        setValidated(true);
    };

    //Send Email  
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_sti0348', 'template_s6covzi', form.current, 'X1bdgvvfHLA9kNU6k')
            .then((result) => {
                console.log(result.text);
                console.log('mensaje enviado');
            }, (error) => {
                console.log(error.text);
            });
    };

    //Reset Form
    const handleReset = () => {
        form.current.reset();
        setValidated(false);
    };

    //Modal
    const handleClose = () => {
        setShow(false)
        if (filled) {
            handleReset();
        }
    };
    const handleShow = () => {
        setShow(true)
    };

    return (

        <Container fluid id='contactForm'>
            <h2 className='text-brown text-center pt-1 pt-md-5 pb-md-3 pt-sm-2'> Contactanos </h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit} ref={form} className='justify-content-start align-items-center form-container'>
                <Row className='mb-3'>
                    <Form.Group as={Col} >
                        <Form.Control required type='text' className='input-text' placeholder='Nombre' name="nombre" />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Control required type='text' className='input-text' placeholder='Apellido' name="apellido" />
                    </Form.Group>
                </Row>
                <Row className='mb-3'>
                    <Form.Group as={Col} >
                        <Form.Control required type='email' className='input-text' placeholder='Email' name="email" />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Control required type='text' className='input-text' placeholder='Telefono (Whatsapp)' name="telefono" />
                    </Form.Group>
                </Row>
                <Row className='mb-3'>
                    <Form.Group as={Col} >
                        <Form.Control required type='text' className='input-text' placeholder='Pais' name="pais" />
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Control required type='text' className='input-text' placeholder='Fecha de nacimiento'
                            name="nacimiento"
                            onFocus={(e) => (e.target.type = "date")} />
                        {/* // onBlur={(e) => (e.target.type = "text")} /> */}
                    </Form.Group>
                </Row>
                <Form.Group className='mb-3' >
                    <Form.Control required type='text' className='input-text' placeholder='En que ciudad quieres estudiar?' name="ciudad" />
                </Form.Group>
                <Row className='mb-3'>
                    <Form.Group as={Col} >
                        <Form.Label htmlFor='whatSelect'> Qué quieres estudiar?</Form.Label>
                        <Form.Select className='input-text' id="whatSelect" name="curso">
                            <option value='Ingles'>Inglés</option>
                            <option value='Cursos'>Cursos VET</option>
                            <option value='educacion superior'>Educacion Superior</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label htmlFor='timeSelect'> Cuánto tiempo quieres estar?</Form.Label>
                        <Form.Select className='input-text' id="timeSelect" name="duracion">
                            <option value='3 meses'>3 meses</option>
                            <option value='6 meses'>6 meses</option>
                            <option value='12 meses'>12 meses</option>
                            <option value='+ 12 meses'>+ 12 meses</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row className='mb-3'>
                    <Form.Group as={Col} >
                        <Form.Label htmlFor='whenSelect'> Cuándo quieres ir?</Form.Label>
                        <Form.Select className='input-text' id="whenSelect" name="cuando">
                            <option value='Enero'>Enero</option>
                            <option value='Febrero'>Febrero</option>
                            <option value='Marzo'>Marzo</option>
                            <option value='Abril'>Abril</option>
                            <option value='Mayo'>Mayo</option>
                            <option value='Junio'>Junio</option>
                            <option value='Julio'>Julio</option>
                            <option value='Agosto'>Agosto</option>
                            <option value='Septiembre'>Septiembre</option>
                            <option value='Octubre'>Octubre</option>
                            <option value='Noviembre'>Noviembre</option>
                            <option value='Diciembre'>Diciembre</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} >
                        <Form.Label htmlFor='englishLevelSelect'> Nivel de Inglés</Form.Label>
                        <Form.Select className='input-text' id="englishLevelSelect" name="nivel">
                            <option value='Basico'>Básico</option>
                            <option value='Intermedio'>Intermedio</option>
                            <option value='Avanzado'>Avanzado</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row className='mb-3'>
                    <Form.Group >
                        <Form.Label>Estás actualmente en Australia?</Form.Label>
                        <div key='default-radio'>
                            <Form.Check
                                value="si"
                                type='radio'
                                id='si'
                                name='radio'
                                label='Si'
                            />
                            <Form.Check
                                value="no"
                                type='radio'
                                id='no'
                                name='radio'
                                label='No'
                            />
                        </div>
                    </Form.Group>
                </Row>
                <Form.Group className='mb-3' >
                    <Form.Label>Comentarios</Form.Label>
                    <Form.Control as='textarea' className='input-text' name='comentarios' />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        required
                        value="acepto"
                        type='checkbox'
                        id='acepto'
                        name='okay'
                        label={
                            <span>
                                Acepto los <Link to='/privacidad'>terminos y condiciones</Link>
                            </span>
                        }
                        feedback="Debes aceptar los terminos antes de continuar."
                        feedbackType="invalid"
                    />
                </Form.Group>
                <Row className='text-center mb-4'>
                    <Col>
                        <Button className='buttons send-button' type='submit' >
                            Enviar
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {filled ? 'Tu Mensaje ha sido enviado con exito!' : 'Por favor rellena todo los campos'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {filled ? 'Pronto nos comunicaremos contigo' : 'Estas cada vez mas cerca de Australia'}
                </Modal.Body>
            </Modal>
        </Container>

    )
}

export default FormComponent