import React from 'react'
import '../styles/Study.scss'
import WhiteLogo from '../assets/WhiteLogo.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'


const Privacy = () => {
    return (

        <Container fluid className='bg-success'>


            <Container>

                <Row className='pb-3 pt-1 pt-sm-3 pt-md-4'>
                    <Col>
                        <h1 className=' fw-bold big-text text-white'>Terminos y</h1>
                        <span><h1 className='sub-title fw-bold big-text'>Condiciones</h1></span>
                        <p className='lh-base text-white mt-0 mt-sm-1 mt-md-3 mt-lg-5'>
                            <h1 className='text-brown fw-bold'>Politica de privacidad</h1>
                            <span>Welcome Mate Australia esta recolectando informacion personal como parte de nuestro proceso de asesoramiento. </span>
                            <span>
                                Welcome Mate Australia reconoce la importancia la privacidad y se compromete a asegurar la privacidad de tu informacion personal, recolectada o recibida por nosotros.
                            </span>
                        </p>
                        <Row className=' pb-3 pt-1 pt-sm-3 pt-md-4'>
                            <NavLink to='/contactanos'>
                                <Button className='team-button buttons'>Contactanos</Button>
                            </NavLink>
                        </Row>
                    </Col>
                    <Col sm ><img src={WhiteLogo} className="d-none d-md-block img-fluid logo-disable" /></Col>

                </Row>


            </Container>
        </Container>


    )
}

export default Privacy