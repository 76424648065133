import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import '../styles/NotFound.scss'
import BrownLogo from '../assets/LogoBrownWelcomeMate.png'

const NotFound = () => {
    return (
        <Container fluid>
            <Container className="text-center">
                <Row className='mb-3'>
                    <div className='text-primary four-hundred-four'>
                        <span>4</span>
                        <img src={BrownLogo} className="img-four-hundred-four" />
                        <span>4</span>
                    </div>
                </Row>
                <Row>
                    <h2 className='text-brown'>UPS, PÁGINA NO ENCONTRADA</h2>
                </Row>
                <Row className='mb-5'>
                    <p>
                        La página solicitada no existe. Por favor, revisa la url y vuelve a intentar.
                    </p>
                </Row>
                <Row className='justify-content-center mb-5'>
                    <Button className='goto-menu-button adventure-button text-white' as={Link} to='/'> Volver al menu</Button>
                </Row>
                {/* <Row className='mt-4'>
                    <h2 className='text-brown'> ¿Por qué elegirnos?</h2>
                </Row>
                <Row>
                    <p className="">
                        Asesoramiento para experiencias educativas. Estudia, Trabaja y vive la experiencia que tanto esperabas en uno de los paises con mejor calidad de vida. Haz amigos de todo el mundo, explota y vive experiencias únicas.
                    </p>
                </Row> */}
            </Container>
        </Container>
    )
}

export default NotFound