import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Study from './pages/Study';
import Home from './pages/Home';
import Navigation from './components/Navbar';
import Footer from './components/Footer';
import NotFound from './pages/NotFound'
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='estudia' element={<Study />} />
          <Route path='trabaja' element={<Study />} />
          <Route path='contactanos' element={<Contact />} />
          <Route path='privacidad' element={<Privacy />} />


          <Route path='*' element={<NotFound />} />

        </Routes>
        <Footer />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
