import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import '../styles/AboutUs.scss'
import character from '../assets/PERSONAJE.png'
import { Link as LinkScroll } from 'react-scroll'

const AboutUs = () => {
    return (
        <Container fluid className=' bg-success' id='aboutUs'>
            <Container>
                <Row >
                    <Col className='justify-content-start align-items-center pt-1 pt-sm-2 pt-md-5 pb-1 pb-sm-3 pb-md-5 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className='left-side-column'>
                            <Row>
                                <h2 className='text-primary'>¿Quiénes Somos?</h2>
                            </Row>
                            <Row>
                                <p className="text-white">
                                    <span className='highlighted-text'>
                                        Welcome Mate es una plataforma que conecta a estudiantes internacionales con instituciones educativas Australianas,
                                    </span>
                                    <span>
                                        para que mediante la visa de estudiante puedan elegir entre una gran cantidad de cursos (de inglés, vocacionales, Technical and Further Education o cursos universitarios).
                                    </span>
                                </p>
                                <p className="text-white">
                                    Con esta visa los alumnos también tienen la posibilidad de trabajar y vivir una experiencia mucho más completa...
                                </p>
                            </Row>
                            <Row className='mt-4'>
                                <h2 className='text-primary'> ¿Por qué elegirnos?</h2>
                            </Row>
                            <Row>
                                <p className="text-white">
                                    Brindamos asesoramiento para experiencias educativas. Contamos con estudiantes y ex-estudiantes que te ayudaran a encontrar lo que mejor te situe. Estudia, Trabaja y vive la experiencia que tanto esperabas en uno de los paises con mejor calidad de vida.
                                </p>
                            </Row>

                            <Row className='mt-5'>
                                <Col>
                                    <LinkScroll to='contactForm' spy={true} smooth={true} offset={25} duration={500}>
                                        <Button className='buttons start-button'> Comienza ahora </Button>
                                    </LinkScroll>
                                </Col>
                                <Col>
                                    {/* <Button className='buttons team-button'> Nuestro equipo</Button> */}
                                </Col>
                            </Row>

                        </div>

                    </Col>
                    <Col className="col-sm">
                        <img src={character} className='img-fluid character' />
                    </Col>
                </Row>
            </Container>
        </Container>
        // Haz amigos de todo el mundo, explota y vive experiencias únicas.
    )
}

export default AboutUs