import React from 'react'
import Adventure from '../components/Adventure'
import Header from '../components/Header'
import AboutUs from '../components/AboutUs'
import StartExperience from '../components/StartExperience'
import FormComponent from '../components/FormComponent'


const Home = () => {
    return (
        <>
            <Header />
            <AboutUs />
            <Adventure />
            <StartExperience />
            <FormComponent/>
        </>
    )
}

export default Home