import React from 'react'
import { Container } from 'react-bootstrap'
import FormComponent from '../components/FormComponent'

const Contact = () => {
    return (
        <Container fluid >
            <FormComponent />
        </Container>



    )
}

export default Contact